export default {
    loadStockConcepts(state, concepts) {
        state.stockConcepts = concepts
    },
    loadDeposits(state, deposits) {
        state.deposits = deposits
    },
    loadPurchaseOrderDetailPendingEntry(state, orders) {
        state.purchaseOrdersDetailPendingEntry = orders
    },
    loadStockMovementDetails(state, movements) {
        state.stockMovementDetails = movements
    },

    addStockConcept(state, concept) {
        state.stockConcepts.unshift(concept)
    },
    removeStockConcept(state, index) {
        state.stockConcepts.splice(index, 1)
    },
    removeStockMovementDetail(state, index) {
        state.stockMovementByOrigin.splice(index, 1)
    }
}