import axios from "axios";
import {host} from "@/store/config";
import {PDF} from "@/types/printFormats";

export default {
  async getPurchaseOrders(params) {
    return await axios.get(host + '/purchase_order', {
      params: params
    })
  },
  async getPurchaseOrderReport(id) {
    return await axios.get(host + '/purchase_order_report', {
      responseType: 'arraybuffer',
      params: {
        id: id,
        extension: PDF.value,
      },
    })
  },
  async getProductPurchases(params) {
    return await axios.get(host + '/historical_purchases_by_product', {
      params: params,
    })
  },
  async getInternalRequestsDetails(params) {
    return await axios.get(host + '/internal_purchase_request_detail', {
      params: params
    })
  },

  async createOrEditPurchaseOrder(order) {
    return await axios.put(host + '/purchase_order', order)
  },
  async deletePurchaseOrder(id) {
    return await axios.delete(host + '/purchase_order', {
      params: {
        id: id,
      },
    })
  },
  async createOrEditInternalPurchaseRequest(internal) {
    return await axios.put(host + '/internal_purchase_request', internal)
  },
  async deleteInternalRequest(id) {
    return await axios.delete(host + '/internal_purchase_request_detail', {
      params: {
        id: id,
      },
    })
  },
  async updateInternalPurchaseOrderDetail(detail) {
    return await axios.post(host + '/internal_purchase_request_detail', detail)
  }
}