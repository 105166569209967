export default {
    getProducts(state) {
        return state.products
    },
    getFilteredProducts: (state) => (searchStr, criteria, searchType) => {
        if (criteria === 'code') {
            return state.products.filter((item) => searchType === 'contains'
                ? item.code.toLowerCase().includes(searchStr.toLowerCase())
                : item.code.toLowerCase().startsWith(searchStr.toLowerCase())
            )
        }
        if (criteria === 'description') {
            return state.products.filter((item) => searchType === 'contains'
                ? item.description.toLowerCase().includes(searchStr.toLowerCase())
                : item.description.toLowerCase().startsWith(searchStr.toLowerCase())
            )
        }
    },
    getBrands(state) {
      return state.brands
    },
}