import axios from "axios";
import {host} from "@/store/config";

export default {
    async deletePeriodClose(id) {
        return axios.delete( host + '/accounting_period_close', {
            params: {
                id: id
            }
        })
    },

    async getPeriodClose() {
        return axios.get( host + '/accounting_period_close')
    },

    async createOrEditPeriodClose(data) {
        return axios.put( host + '/accounting_period_close', data)
    },

    async getPaymentMethods() {
        return await axios.get( host + '/payment_method')
    },
    async getCollectionMethods() {
        return await axios.get( host + '/collection_method')
    },
    async getAccountPlannings(params) {
        return await axios.get( host + '/account_planning', {
            params: params
        })
    },

    async deleteAccountPlanning(id) {
        return await axios.delete( host + '/account_planning', {
            params: {
                id: id
            }
        })
    },
    async getSubCategories() {
        return await axios.get( host + '/sub_category')
    },
    async getPaymentMethodAccountParameters(params) {
        return await axios.get( host + '/payment_method_account_parameter', {
            params: params
        })
    },
    async getCommonAccountingEntries(params) {
        return await axios.get( host + '/common_accounting_entry', {
            params: params
        })
    },
    async getAccountEntryMovements(accountEntryId) {
        return await axios.get( host + '/account_entry_movement_searcher', {
            params: {
                accountEntryId
            }
        })
    },

    async createOrEditAccountPlanning(account) {
        return await axios.post( host + '/account_planning', account)
    },
    async createOrEditAccountingEntry(account) {
        return await axios.post( host + '/common_accounting_entry', account)
    },
    async createOrEditPaymentMethodAccountParameter(paymentMethodAccount) {
        return await axios.post( host + '/payment_method_account_parameter', paymentMethodAccount)
    },
    async separatePaymentMethodAccount(accountId) {
        return await axios.put(host + '/payment_method_account_separate', null, {
            params: {
                accountId
            }
        })
    },
    async deletePaymentMethodAccountParameter(id) {
        return await axios.delete( host + '/payment_method_account_parameter', {
            params: {
                id
            }
        })
    }
}