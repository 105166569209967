import axios from "axios";
import {host} from "@/store/config";

export default {
    async getParameters() {
        return await axios.get(host + '/parameters')
    },
    async saveParameters(data) {
        return await axios.put(host + '/parameters', data)
    },
    async getExchangeRate() {
        return await axios.get('https://api-dolar-argentina.herokuapp.com/api/dolaroficial')
    }
}