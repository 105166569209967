import axios from "axios";
import {host} from "@/store/config";

export default {
    async getStockConcepts(params) {
        return await axios.get(host + '/stock_concept', {
            params: params
        })
    },
    async getDeposits() {
        return await axios.get(host + '/deposit')
    },
    async getPurchaseOrdersDetailPendingEntry(productId) {
        return await axios.get(host + '/purchase_order_detail_pending_entry_searcher', {
            params: {
                productId: productId,
            }
        })
    },
    async getStockMovementByOrigin(params) {
        return await axios.get(host + '/stock_movement_searcher_by_origin', {
            params: params
        })
    },

    async createOrEditStockConcept(concept) {
      return await axios.put(host + '/stock_concept', concept)
    },
    async deleteStockConcept(id) {
        return await axios.delete(host + '/stock_concept', {
            params: {
                id
            }
        })
    },

    async createStockMovement(movement) {
        return await axios.put(host + '/stock_movement', movement)
    }
}