import financesEndpoints from "@/store/endpoints/financesEndpoints";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";
import {PDF} from "@/types/printFormats";
import {setTablePagination} from "@/utils/paginator";

export default {
    async getExchangeParameters(context) {
        const response = await financesEndpoints.getExchangeParameters()
        if (response.status === 200) context.commit('loadExchangeParameters', response.data)
    },
    async getPricesLists(context, params) {
        const response = await financesEndpoints.getPricesLists(params)
        if (response.status === 200) {
            context.commit('loadPricesLists', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getPriceListDetails(context, params) {
        const response = await financesEndpoints.getPriceListDetails(params)
        if (response.status === 200) {
            context.commit('loadProductsInList', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getBanks(context, params) {
        const response = await financesEndpoints.getBanks(params)
        if (response.status === 200) {
            context.commit('loadBanks', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getBankAccounts(context, params) {
        const response = await financesEndpoints.getBankAccounts(params)
        if (response.status === 200) {
            context.commit('loadBankAccounts', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getBankConcepts(context, params) {
        const response = await financesEndpoints.getBankConcepts(params)
        if (response.status === 200) {
            context.commit('loadBankConcepts', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getBankMovements(context, params) {
        const response = await financesEndpoints.getBankMovements(params)
        if (response.status === 200) {
            context.commit('loadBankMovements', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getBankMovementDetailReport(context, movementId) {
        const data = {
            params: {
                format: PDF.value,
                id: movementId,
            },
            endpoint: '/bank_movement_detail_report',
        }

        const response = await reportsEndpoints.getReport(data)
        if (response.status === 200) reportOutputResolver.openFile(response)
    },
    async getBankCards(context) {
        const response = await financesEndpoints.getBankCards()
        if (response.status === 200) context.commit('loadBankCards', response.data)
    },
    async getWallets(context) {
        const response = await financesEndpoints.getWallets()
        if (response.status === 200) context.commit('loadWallets', response.data)
    },
    async getPerceptionTxt(context, perceptionData) {
        const response = await financesEndpoints.getPerceptionTxt(perceptionData)
        if (response.status === 200) reportOutputResolver.downloadFile(response)
    },

    async saveExchangeRateParameter(context, exchange) {
        const response = await financesEndpoints.saveExchangeRateParameter(exchange)
        if (response.status === 201) context.commit('addExchangeRateParameter', exchange)
    },
    async deleteExchangeRateParameter(context, exchange) {
        const index = context.state.exchangeRateParameters.indexOf(exchange)
        const response = await financesEndpoints.deleteExchangeRateParameter(exchange.id)
        if (response.status === 200) context.commit('removeExchangeRateParameter', index)
    },

    async createOrEditPricesList(context, pricesList) {
        return await financesEndpoints.createOrEditPricesList(pricesList)
    },
    async deletePricesList(context, index) {
        let list = context.state.pricesLists[index]
        return await financesEndpoints.deletePricesList(list.id)
    },
    async createOrEditPriceListDetail(context, detail) {
        return await financesEndpoints.createOrEditPriceListDetail(detail)
    },
    async removeDetailFromList(context, id) {
        return await financesEndpoints.removeDetailFromList(id)
    },

    async createOrEditBank(context, data) {
        return await financesEndpoints.createOrEditBank(data.bank)
    },
    async deleteBank(context, index) {
        const bank = context.state.banks[index]
        return await financesEndpoints.deleteBank(bank.id)
    },

    async createBankAccount(context, bankAccount) {
        return await financesEndpoints.createOrEditBankAccount(bankAccount)
    },
    async editBankAccount(context, data) {
        return await financesEndpoints.createOrEditBankAccount(data.bankAccount)
    },
    async deleteBankAccount(context, index) {
        const bankAcc = context.state.bankAccounts[index]
        return await financesEndpoints.deleteBankAccount(bankAcc.id)
    },

    async createOrEditBankConcept(context, bankConcept) {
        return await financesEndpoints.createOrEditBankConcept(bankConcept)
    },
    async separateBankConceptAccount(context, accountId) {
        await financesEndpoints.separateBankConceptAccount(accountId)
    },
    async deleteBankConcept(context, index) {
        const concept = context.state.bankConcepts[index]
        return await financesEndpoints.deleteBankConcept(concept.id)
    },

    async createOrEditBankMovement(context, bankMovement) {
        const response = await financesEndpoints.createOrEditBankMovement(bankMovement)
        if (response.status === 201) {
            context.commit('addBankMovement', bankMovement)
        }
        return response
    },
    async deleteBankMovement(context, index) {
        const movement = context.state.bankMovements[index]
        return await financesEndpoints.deleteBankMovement(movement.id)
    },

    async createOrEditCard(context, data) {
        const response = await financesEndpoints.createOrEditCard(data.card)
        if (response.status === 201) context.commit('addBankCard', data.card)

        return response
    },
    async deleteBankCard(context, index) {
        let card = context.state.bankCards[index]
        const response = await financesEndpoints.deleteBankCard(card.id)

        if (response.status === 200) context.commit('removeBankCard', index)
    },

    async createOrEditWallet(context, wallet) {
        const response = await financesEndpoints.createOrEditWallet(wallet)
        if (response.status === 201) context.commit('addWallet', wallet)
        return response
    },
    async deleteWallet(context, index) {
        let wallet = context.state.wallets[index]
        const response = await financesEndpoints.deleteWallet(wallet.id)
        if (response.status === 200) context.commit('removeWallet', index)
    }
}
