import axios from "axios";
import {host} from "@/store/config";

export default {
  async getReport(report) {
    return await axios.get(host + report.endpoint, {
      responseType: 'arraybuffer',
      params: report.params,
    })
  },
}