export default {
  getExchangeRateParameters(state) {
    return state.exchangeRateParameters
  } ,
  getPricesLists(state) {
    return state.pricesLists
  },
  getPricesListDetails(state) {
    return state.pricesListDetails
  },
  getAvailablePricesLists(state) {
    return customerPricesList => {
      let pricesLists = Array.from(state.pricesLists) // Copy of array. It will not affect the state

      customerPricesList.forEach(customerList => {
        pricesLists.forEach(list => {
          if (list.id.match(customerList.id)) {
            pricesLists.splice(pricesLists.indexOf(list), 1)
          }
        });
      });
      return pricesLists
    }
  },

  getBanks(state) {
    return state.banks
  },
  getBankAccounts(state) {
    return state.bankAccounts
  },
  getBankConcepts(state) {
    return state.bankConcepts
  },
  getBankMovements(state) {
    return state.bankMovements
  },
  getBankCards(state) {
    return state.bankCards
  },
  getBankCardsByType(state) {
    return type => {
      return state.bankCards.filter(card =>
        card.type.match(type)
      )
    }
  },
  getWallets(state) {
    return state.wallets
  },
}