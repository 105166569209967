import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'
import themes from "@/theme";

Vue.use(Vuetify);

const currentEnv = process.env.VUE_APP_ENV || 'development';

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            light: themes(currentEnv, false),
            dark: themes(currentEnv, true),
        },
    },
})
