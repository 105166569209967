export default {
  loadCorrelatives(state, correlatives) {
    state.correlatives = correlatives
  },
  loadPressureTests(state, tests) {
    state.pressureTests = tests
  },
  loadReceptionControlProducts(state, products) {
    state.receptionControlProducts = products
  },

  addCorrelative(state, correlative) {
    state.correlatives.push(correlative)
  },
  removeCorrelative(state, index) {
    state.correlatives.splice(index, 1)
  },

  addPressureTest(state, test) {
    state.pressureTests.unshift(test)
  },
  updatePressureTest(state, data) {
    Object.assign(state.pressureTests[data.index], data.pressureTest)
  },
  removePressureTest(state, index) {
    state.pressureTests.splice(index, 1)
  },

  updateQualityProductReception(state, data) {
    const controlProduct = state.receptionControlProducts[data.index]
    if (controlProduct) {
      Object.assign(controlProduct, data.quality)
    }
  }
}