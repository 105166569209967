import customerOrdersEndpoints from "@/store/endpoints/customerOrdersEndpoints";
import {setTablePagination} from "@/utils/paginator";

export default {
    async getCustomerOrderDetails(context, params) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetails(params)
        if (response.status === 200) {
            context.commit('loadCustomerOrderDetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getCustomerOrderDetailsAuthorizedForDelivery(context) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetailsAuthorizedForDelivery()
        if (response.status === 200) {
            context.commit('loadCustomerOrderDetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },

    async createOrEditCustomerOrder(context, data) {
        return await customerOrdersEndpoints.createOrEditCustomerOrder(data)
    },
    async deleteCustomerOrder(context, order) {
        let index = context.state.customerOrders.indexOf(order)
        const response = await customerOrdersEndpoints.deleteCustomerOrder(order.id)
        if (response.status === 200) context.commit('removeCustomerOrder', index)
    },
    async deleteOrderDetail(context, data) {
        return await customerOrdersEndpoints.deleteOrderDetail(data)
    },
    async authorizeDelivery(context, data) {
        const response = await customerOrdersEndpoints.authorizeDelivery(data.detail)
        if (response.status === 201) context.commit('uploadCustomerOrderDetail', data)
        return response
    },
    async deleteAuthorizedDelivery(context, id) {
        await customerOrdersEndpoints.deleteAuthorizedDelivery(id)
    },
}
