import axios from "axios";
import {host} from "@/store/config";

export default {
  async getFormulasByProduct(id) {
    return await axios.get(host + '/formula_search_by_product', {
      params: {
        id: id,
      }
    })
  },
  async createOrEditFormula(formula) {
    return await axios.put(host + '/formula_creator', formula)
  },
  async deleteFormula(formulaId, parentId) {
    return await axios.delete(host + '/formula', {
      params: {
        nodeId: formulaId,
        parentId: parentId
      }
    })
  },
}