import personsEndpoints from '@/store/endpoints/peopleEndpoints'

export default {
  async getProvinces(context) {
    const response = await personsEndpoints.getProvinces()
    if (response.status === 200) {
      context.commit('loadProvinces', response.data)
    }
  },
  async getLocalitiesByProvince(context, provinceId) {
    const response = await personsEndpoints.getLocalitiesByProvince(provinceId)
    if (response.status === 200) {
      context.commit('loadLocalities', response.data)
    }
  },
}