export default {
  loadFormulaChildren(state, children) {
    state.formulaChildren = children
  },
  addFormulaChild(state, child) {
    state.formulaChildren.push(child)
  },
  removeFormula(state, index) {
    state.formulaChildren.splice(index, 1)
  },
  cleanFormulas(state) {
    state.formulaChildren = []
  }
}