import axios from "axios"
import {host} from "@/store/config";
import {AUTHORIZED_FOR_DELIVERY} from "@/types/customerOrderStatus";

export default {
  async getCustomerOrderDetails(params) {
    return await axios.get(host + '/customer_order_detail_get', {
      params: params
    })
  },
  async getCustomerOrderDetailsAuthorizedForDelivery() {
    return await axios.get(host + '/customer_order_detail_authorized_for_delivery')
  },
  async getCustomerOrderDetailsByCustomer(data) {
    return await axios.get(host + '/customer_order_details_by_customer', {
      params: {
        customerId: data.customerId,
        status: data.status
      }
    })
  },
  async getCustomerOrderDetailsByCustomerPaged(pagination) {
    return await axios.get(host + '/customer_order_details_by_customer', {
      params: {
        customerId: pagination.extraParams.customerId,
        status: pagination.extraParams.status,
        limit: pagination.limit,
        page: pagination.page
      }
    })
  },
  async getCustomerOrderDetailsByOrderNumber(data) {
    return await axios.get(host + '/customer_order_details_by_number', {
        params: {
          number: data.number,
          status: data.status
        }
    })
  },
  async getCustomerOrderDetailsByOrderNumberPaged(pagination) {
    return await axios.get(host + '/customer_order_details_by_number', {
      params: {
        number: pagination.extraParams.orderNumber,
        status: pagination.extraParams.status,
        limit: pagination.limit,
        page: pagination.page
      }
    })
  },

  async createOrEditCustomerOrder(data) {
    let formData = new FormData()

    if (data.attachment) {
      data.attachment.forEach(function (file) {
        formData.append('files[]', file, file.fileName)
      })
    } else {
      data.customerOrder.attachment.forEach(function (file) {
        formData.append('files[]', file, file.name)
      })
    }
    formData.append('data', JSON.stringify(data.customerOrder))

    return await axios.post(host + '/customer_order', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async deleteCustomerOrder(id) {
    return await axios.delete(host + '/customer_order', {
      params: {
        id: id,
      }
    })
  },

  async deleteOrderDetail(data) {
    return await axios.delete(host + '/customer_order_detail', {
      params: {
        id: data.id,
        force: data.force,
      }
    })
  },

  async authorizeDelivery(detail) {
    return await axios.put(host + '/customer_order_detail_delivery_authorizer', detail)
  },
  async deleteAuthorizedDelivery(id) {
    await axios.post(host + '/customer_order_detail_delivery_authorized_deleter', null, {
      params: {
        id: id,
      }
    })
  },
}