<template>
  <v-dialog
    v-model="isLoading"
    persistent
    width="300"
  >
    <v-card>
      <v-card-title class="blue--text text-md">
        Cargando...
        <v-progress-circular
          indeterminate
          color="blue"
          class="mb-0 ml-4"
        ></v-progress-circular>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      isLoading: Boolean,
    }
  }

</script>