<template>
  <div>
    {{ getMessage }}
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    type: String,
    message: String,
  },
  data() {
    return {
      duration: 7000
    }
  },
  computed: {
    getMessage: function () {
      if (this.show) {
        this.$toasted.show(this.message, {
          action: {
            text: 'Cerrar',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
              this.$emit('hide-notification')
            }
          },
          type: this.type,
          theme: 'bubble',
          duration: this.duration
        })
        setTimeout(() => {
          this.$emit('hide-notification')
        }, this.duration)
      }
    },
  }
}
</script>