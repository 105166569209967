import subfamiliesEndpoints from "@/store/endpoints/subfamiliesEndpoints";
import {setTablePagination} from "@/utils/paginator";

export default {
    async getSubfamilies(context, params) {
        const response = await subfamiliesEndpoints.getSubfamilies(params)
        if (response.status === 200) {
            context.commit('loadSubfamilies', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async createSubfamily(context, data) {
        return await subfamiliesEndpoints.createOrEditSubFamily(data)
    },
    async editSubfamily(context, data) {
        const response = await subfamiliesEndpoints.createOrEditSubFamily(data.item)
        if (response.status === 201) {
            context.commit('updateSubfamily', data)
        }
        return response
    },
    async deleteSubfamily(context, index) {
        let item = context.state.subfamilies[index]
        return await subfamiliesEndpoints.deleteSubfamily(item.id)
    },
}