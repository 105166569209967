import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from "@/store";
import axios from 'axios';
import Toasted from 'vue-toasted';
import { VueMaskDirective } from 'v-mask'

// Token para las request
const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

Vue.use(Toasted)
Vue.config.productionTip = false
Vue.directive('mask', VueMaskDirective);
