import colors from "vuetify/lib/util/colors";

export function themes(env = 'development', isDark = false) {
    if (env === 'dev') {
        return {
            primary: colors.purple,
            secondary: colors.grey.darken1,
            accent: colors.shades.black,
            error: colors.red.accent3
        }
    }
    return {
        primary: isDark ? colors.teal.darken2 : colors.teal.lighten2,
        secondary: colors.grey.darken1,
        cyan: isDark ? colors.cyan.darken2 : colors.cyan.lighten2,
        accent: colors.shades.black,
        error: colors.red.accent3,
        success: '#147817'
    }
}

export default themes;