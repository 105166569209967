import store from "@/store";

export function setTablePagination(context, data) {
    context.commit('app/setTotalItems', data.total, {root: true})
    context.commit('app/setTotalPages', data.pages, {root: true})
}

export function resetTableOptions(itemsPerPage = 10) {
    const page = store.getters['app/getPage']
    if (page === 1) {
        return false
    }
    store.commit('app/setTableOptions', {page: 1, itemsPerPage: itemsPerPage}, {root: true})
}