<template>
  <div>
    <v-app-bar >
      <v-app-bar-nav-icon @click.stop="showMenu = !showMenu"></v-app-bar-nav-icon>
      <v-toolbar-title>Menú</v-toolbar-title>

      <VSpacer />
      <v-btn v-if="isDevelopment" class="mr-10" @click="$emit('refresh-view')">Refrescar vista</v-btn>
      <v-icon class="mr-3" @click="toggleTheme">{{ getThemeIcon }}</v-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="showMenu"
      dark
      color="primary darken-1"
      class=""
      temporary
      app
      width="400"
    >
  
    <v-toolbar flat>
      <v-list-item>
<!--        <v-list-item-avatar>-->
<!--            <img :src="getAvatarSrc" alt="Avatar"/>-->
<!--        </v-list-item-avatar>-->
  
        <v-list-item-content>
          <v-list-item-title class="title">Metalúrgica BP</v-list-item-title>
          <v-divider/>
          <v-list-item-subtitle>
            Usuario: {{ getUser }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Sector: {{ getSector }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
  
    <v-card-text>
      <v-treeview :items="items" open-on-click hoverable item-key="name">
        <template v-slot:prepend="{ item }">
          <v-icon v-text="item.icon"></v-icon>
        </template>

        <template v-slot:label="{ item }">
          <a class="linkNav" v-if="!item.path">{{ item.name }}</a>
          <router-link
            v-if="item.path" :to="item.path || '#' "
          >
            <span class="linkNav" @click="hideMenu()">{{ item.name }}</span>
          </router-link>
        </template>
      </v-treeview>
    </v-card-text>

    <v-divider></v-divider>

    <v-list color="primary darken-2">
      <v-list-item @click="goToBackOffice">
        <v-icon class="mr-2">mdi-desk</v-icon>
        <v-list-item-title>Back Office</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list color="red darken-4">
      <v-list-item @click="logout">
        <v-icon class="mr-2">mdi-power</v-icon>
        <v-list-item-title>Cerrar sesión</v-list-item-title>
      </v-list-item>
    </v-list>
    </v-navigation-drawer>
  </div>

</template>
  
<script>
import { mapGetters } from 'vuex'
import {host} from "@/store/config";
export default {
  methods: {
    hideMenu() {
      this.showMenu = false
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    async logout() {
      await this.$store.dispatch("app/logout")
      await this.$router.push("/login")
    },
    goToBackOffice() {
      window.location.replace(host + '/admin/backoffice')
    },
  },

  computed:{
    ...mapGetters({
      getAvatarSrc: "app/getAvatarSrc",
      getUser: "app/getUser",
      getSector: "app/getSector",
    }),
    getThemeIcon() {
      return this.$vuetify.theme.dark
        ? 'mdi-white-balance-sunny'
        : 'mdi-weather-night'
    },
    isDevelopment() {
      return process.env.NODE_ENV === 'development'
    }
  },  

  data() {
    return {
      showMenu: true,
      srcAvatar: "",
      clipped: false,
      items: [
        {
          name: "Home",
          icon: "mdi-home",
          path: "/",
        },
        {
          name: "Articulos",
          icon: "mdi-star",
          children: [
            { name: "Lista", icon: "mdi-format-list-bulleted", path: "/products" },
            { name: "Familia", icon: "mdi-sitemap", path: "/families" },
            { name: "Sub Familia", icon: "mdi-sitemap", path: "/subfamilies" },
            { name: "Marcas", icon: "mdi-tag-multiple", path: "/brands" },
          ],
        },
        {
          name: "Clientes",
          icon: "mdi-account-multiple",
          children: [
            { name: "Listado", icon: "mdi-format-list-bulleted", path: "/customers/list" },
            { name: "CC Clientes", icon: "mdi-cash", path: "/customers/customersCA" },
            {
              name: "Lista de Precios",
              icon: "mdi-currency-usd",
              children: [
                { name: "Nueva Lista", icon: "mdi-playlist-plus", path: "/customers/prices_lists/new_list" },
                { name: "Agregar Articulos", icon: "mdi-plus", path: "/customers/prices_lists/add_products" },
                { name: "Ver Precios", icon: "mdi-eye", path: "/customers/prices_lists/see_prices" },
              ]
            },
            {
              name: "Nueva Cotización",
              icon: "mdi-currency-usd",
              path: "/customers/new_quotation",
            },
            {
              name: "Listado de Cotizaciones",
              icon: "mdi-format-list-bulleted",
              path: "/customers/quotation_list",
            },
            { name: "Transportes", icon: "mdi-truck", path: "/customers/transports" },
            { name: "Vendedores", icon: "mdi-account-multiple", path: "/customers/sellers" },
          ],
        },
        {
          name: "Proveedores",
          icon: "mdi-truck",
          children: [
            { name: "Listado", icon: "mdi-format-list-bulleted", path: "/suppliers/list" },
            { name: "CC Proveedores", icon: "mdi-cash", path: "/suppliers/suppliersCA" },
            { name: "Imputaciones/Gastos", icon: "mdi-cash", path: "/suppliers/imputations_expenses" },
            { name: "Agenda de Pagos", icon: "mdi-clipboard-text", path: "/suppliers/payments_schedule" },
          ]
        },
        { name: "Parametros",
          icon: "mdi-tune-vertical-variant",
          children: [
            { name: "Generales", icon: "mdi-cog", path: "/parameters/general" },
            { name: "Contabilidad", icon: "mdi-currency-usd", path: "/parameters/accounting" },
          ]
        },
        {
          name: "Ingeniería",
          icon: "mdi-hammer-wrench",
          children: [
            { name: "Historial de revisiones", icon: "mdi-history", path: "/engineering/revisions_history" },
            { name: "Fórmulas",
              icon: "mdi-function-variant",
              children: [
                { name: "Simple", icon: "mdi-function-variant", path: "/engineering/formulas/simple" },
                { name: "Lote", icon: "mdi-family-tree", path: "/engineering/formulas/batch" },
              ],
            },
          ]
        },
        {
          name: "Calidad",
          icon: "mdi-eye-outline",
          children: [
            { name: "Numeración Correlativa", icon: "mdi-format-list-numbered", path: "/quality/correlative_numbering" },
            { name: "Control Estanqueidad", icon: "mdi-eye-outline", path: "/quality/tightness_control" },
            { name: "Control de Recepción", icon: "mdi-cash", path: "/quality/reception_control" },
          ]
        },
        {
          name: "Produccion",
          icon: "mdi-account-hard-hat",
          children: [
            {
              name: "Ordenes de Trabajo",
              icon: "mdi-clipboard-text",
              children: [
                { name: "Generar lote", icon: "mdi-plus-box-multiple-outline", path: "/production/new_batch_order" },
                { name: "Nueva OT", icon: "mdi-plus", path: "/production/new_work_order" },
                { name: "Tablero de Programación", icon: "mdi-clipboard-text", path: "/production/programming_board" },
                { name: "Seguimiento OT", icon: "mdi-eye", path: "/production/order_tracing" },
                { name: "Ver OTs", icon: "mdi-eye", path: "/production/work_orders_list" },
                { name: "Asociar OT con Pedido", icon: "mdi-swap-horizontal", path: "/production/associate_wo_customer_order" },
                { name: "Asociar OT con OT", icon: "mdi-swap-horizontal", path: "/production/associate_wo_wo" },
              ],
            },
            { name: "Tablero de Prioridades", icon: "mdi-plus", path: "/production/priority_board" },
            {
              name: "Pedidos",
              icon:'mdi-clipboard-text',
              children: [
                { name: "Nuevo", icon: "mdi-plus", path: "/production/new_order" },
                { name: "Pendientes", icon: "mdi-clock-fast", path: "/production/pending_customer_orders" },
                { name: "Entregados", icon: "mdi-checkbox-marked-circle-outline", path: "/production/delivered_customer_orders" },
                { name: "Autorizar Entregas", icon: "mdi-check-underline-circle-outline", path: "/production/authorize_deliveries" },
                { name: "Entregas Autorizadas", icon: "mdi-checkbox-multiple-marked-outline", path: "/production/authorized_deliveries" },
              ],
            },
            {
              name: "Cálculo de Paneles",
              icon: 'mdi-function-variant',
              children: [
                {
                  name: "Placa Barra",
                  icon: 'mdi-function-variant',
                  children: [
                    {
                      name: "Nuevo Cálculo",
                      icon: "mdi-plus",
                      path: "/production/panel_calculations/new_bar_plate",
                    },
                    {
                      name: "Imprimir Cálculo",
                      icon: "mdi-printer",
                      path: "/production/panel_calculations/print_bar_plate",
                    },
                  ],
                },
                {
                  name: "Panel de Tubos",
                  icon: 'mdi-function-variant',
                  children: [
                    {
                      name: "Panel Simple/Doble",
                      icon: "mdi-plus",
                      path: "/production/panel_calculations/simple_double_tube",
                    },
                    {
                      name: "Panel Intercooler",
                      icon: "mdi-plus",
                      path: "/production/panel_calculations/intercooler",
                    },
                    {
                      name: "Imprimir Cálc. Panel Simple/Doble",
                      icon: "mdi-printer",
                      path: "/production/panel_calculations/print_simple_double_tube",
                    },
                    {
                      name: "Imprimir Cálculo Panel Intercooler",
                      icon: "mdi-printer",
                      path: "/production/panel_calculations/print_intercooler",
                    },
                    {
                      name: "Consumo de Materiales",
                      icon: "mdi-printer",
                      path: "/produccion/consumoDeMaterialesPanelTubo",
                    },
                    {
                      name: "Lista de Materiales",
                      icon: "mdi-format-list-bulleted",
                      path: "/produccion/tubos/listaDeMateriales",
                    },
                  ],
                },
              ],
            },

            {
              name: "Control de Procesos",
              icon: 'mdi-eye',
              children: [
                {
                  name: "Paneles",
                  icon: "mdi-eye",
                  path: "/production/control/panel_process_tracing"
                },
                {
                  name: "Cierre de procesos",
                  icon: "mdi-eye",
                  path: "/production/control/process_closure"
                },
              ],
            },
            {
              name: 'Reparaciones',
              icon: 'mdi-wrench',
              children: [
                {
                  name: 'Listado',
                  icon: 'mdi-format-list-bulleted',
                  path: '/production/repairs/list'
                },
                {
                  name: 'Conceptos',
                  icon: 'mdi-format-list-bulleted',
                  path: '/production/repairs/concepts'
                }
              ]
            }
          ],
        },
        {
          name: "RR.HH",
          icon: "mdi-account-multiple",
          children: [
            {
              name: "Personal",
              icon: "mdi-account-multiple",
              path: "/employees/list",
            },
          ],
        },
        {
          name: "Bancos",
          icon: "mdi-bank",
          children: [
            {                   
              name: "Bancos",
              icon: "mdi-format-list-bulleted",
              path: "/banks/list",
            },
            {
              name: "Cuentas",
              icon: "mdi-book-open-variant",
              path: "/banks/accounts" },
            {
              name: "Tarjetas de Crédito/Débito",
              icon: "mdi-credit-card",
              path: "/banks/cards",
            },
            {
              name: "Billeteras virtuales",
              icon: "mdi-wallet-outline",
              path: "/banks/wallets",
            },
            {
              name: "Conceptos",
              icon: "mdi-format-list-bulleted",
              path: "/banks/concepts",
            },
            {
              name: "Nuevo Movimiento",
              icon: "mdi-plus",
              path: "/banks/new_movement",
            },
            {
              name: "Listado de Movimientos",
              icon: "mdi-swap-horizontal",
              path: "/banks/movements",
            },
          ],
        },
        {
          name: "Contabilidad",
          icon: "mdi-book-open-variant",
          children: [
            {
              name: "Cuentas",
              icon: "mdi-shopping-outline",
              path: "/accounting/account_plannings",
            },
            {
              name: "Asientos Contables",
              icon: "mdi-swap-horizontal",
              path: "/accounting/common_accounting_entries",
            }
          ],
        },
        {
          name: "Compras",
          icon: "mdi-shopping",
          children: [
            {
              name: "Nueva Orden de Compra",
              icon: "mdi-plus",
              path: "/purchase_orders/new",
            },
            {
              name: "Ver Orden de Compra",
              icon: "mdi-eye",
              path: "/purchase_orders/list",
            },
            {
              name: "Modificar Orden de Compra",
              icon: "mdi-pencil",
              path: "/purchase_orders/modify",
            },
            {
              name: "Nuevo Pedido a Compras",
              icon: "mdi-plus",
              path: '/purchase_orders/new_internal_order',
            },
            {
              name: "Ver Pedidos Internos",
              icon: "mdi-eye",
              path: "/purchase_orders/internal_orders_list",
            },
          ],
        },
        {
          name: "Stock",
          icon: "mdi-store",
          children: [
            {
              name: "Nueva Entrada/Salida",
              icon: "mdi-swap-horizontal",
              path: "/stock/entry_departure",
            },
            {
              name: "Listado de Movimientos",
              icon: "mdi-format-list-bulleted",
              path: "/stock/entries_list",
            },
            {
              name: "Conceptos",
              icon: "mdi-playlist-edit",
              path: "/stock/stock_movement_concepts",
            },
            {
              name: "Salida de Remitos",
              icon: "mdi-arrow-u-right-top",
              children: [
                {
                  name: "Nueva salida",
                  icon: "mdi-plus",
                  path: "/stock/new_dispatch_note_departure"
                },
                {
                  name: "Listado de salidas",
                  icon: "mdi-format-list-bulleted",
                  path: "/stock/departures_list"
                }
              ]
            },
          ],
        },

        {
          name: "Remitos",
          icon: "mdi-clipboard-text",
          children: [
            { name: "Nuevo Remito", icon: "mdi-plus", path: "/dispatch_notes/new_dispatch_note" },
            {
              name: "Ver Remitos",
              icon: "mdi-eye",
              path: "/dispatch_notes/show_dispatch_notes",
            },
            {
              name: "Anular Remitos Pendientes de Facturación",
              icon: "mdi-pencil",
              path: "/dispatch_notes/cancel_dispatch_notes",
            },
            {
              name: "Asociar Remito/Correlativo",
              icon: "mdi-format-list-numbered",
              path: "/dispatch_notes/associate_dispatch_note_correlative",
            },
            {
              name: "Asociar Remito/Factura",
              icon: "mdi-clipboard-text",
              path: "/dispatch_notes/assoc_dispatch_invoice",
            },
            {
              name: "Asociar Remito/Pedido",
              icon: "mdi-clipboard-text",
              path: "/dispatch_notes/assoc_dispatch_customer_order",
            },
          ],
        },
        {
          name: "Utilitarios",
          icon: "mdi-car-estate",
          children: [
            {
              name: "Hoja de Ruta",
              icon: "mdi-map-marker",
              path: "/utilitarios/hojaDeRuta",
            },
          ],
        },
        {
          name: 'AFIP',
          icon: 'mdi-cash',
          children: [
            {
              name: "Carga manual de Cbtes. AFIP",
              icon: "mdi-cash",
              path: "/afip/proofs_manual_load",
            },
            {
              name: "Verificación de Cbtes. Recibidos",
              icon: "mdi-checkbox-multiple-outline",
              path: "/afip/verify_received_proofs",
            },
            {
              name: "Txt Percepción",
              icon: "mdi-clipboard-text",
              path: "/afip/perception_txt",
            },
          ]
        },
        {
          name: "Reportes",
          icon: "mdi-finance",
          children: [
            {
              name: "Producción",
              icon: 'mdi-account-hard-hat',
              children: [
                {
                  name: "OTs Por Sector",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/production/work_orders_by_sector",
                },
                {
                  name: "OTs Por Cliente",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/production/work_orders_by_customer",
                },
                {
                  name: "Histórico OTs por Artículo",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/production/WO_history_by_product",
                },
              ],
            },
            {
              name: "Ventas",
              icon: 'mdi-cash',
              children: [
                { name: "Libro IVA Ventas", icon: 'mdi-clipboard-text', path: "/reports/sells/sells_iva" },
                { name: "Ventas por Provincia", icon: 'mdi-clipboard-text', path: "/reports/sells/sells_by_province" },
                { name: "Ranking de ventas", icon: 'mdi-clipboard-text', path: "/reports/sells/sells_ranking" },
                {
                  name: "Art. vendidos por cliente y período",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/sells_by_customer_and_period",
                },
                {
                  name: "Ventas por Familia y Sub Familia",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/sells_by_family_and_subfamily",
                },
                {
                  name: "Intereses por pago fuera de término",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/interests_for_out_term_payments",
                },
                {
                  name: "Resumen Saldo Deudor",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/expired_balances_summary",
                },
                {
                  name: "Resumen Cuenta Corriente Cliente",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/customer_ca_summary",
                },
                {
                  name: "Comisiones a Vendedores",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/sells_commissions",
                },
                {
                  name: "Cobranzas Entre Fechas",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/collections_in_a_period",
                },
                { name: "Listas de Precios", 
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/prices_list" },
                {
                  name: "Listas de Precios por Cliente",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/sells/prices_lists_by_customer",
                },
              ],
            },
            {
              name: "Proveedores",
              icon: 'mdi-truck',
              children: [
                {
                  name: "Resumen Cuenta Corriente",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/suppliers/supplier_ca_summary",
                },
                {
                  name: "Libro IVA Compras",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/suppliers/purchases_book",
                },
                {
                  name: "Resumen Saldo Acreedor",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/suppliers/creditor_balance",
                },
                { name: "Pagos entre Fechas", icon: 'mdi-clipboard-text', path: "/reports/suppliers/payment_orders" },
                {
                  name: "Acumulado por Imputaciones",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/suppliers/accumulated_by_imputations",
                },
              ],
            },
            {
              name: "Pedidos de clientes",
              icon: 'mdi-clipboard-text',
              children: [
                {
                  name: "Art. Pedidos por Cliente y Período",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/customer_orders/orders_by_customer_period",
                },
              ],
            },
            {
              name: "Stock",
              icon: "mdi-store",
              children: [
                {
                  name: "Histórico de Movimientos de Artículos",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/stock/stock_movement_history",
                },
              ],
            },
            {
              name: "Bancos",
              icon: "mdi-bank",
              children: [
                {
                  name: "Histórico de Movimientos Bancarios",
                  icon: "mdi-swap-horizontal",
                  path: "/reports/banks/bank_movement_history",
                },
                {
                  name: "Inventario Cheques de Terceros",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/banks/third_party_checks_inventory",
                },
                {
                  name: "Cheques de Terceros Ingresados y Entregados",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/banks/third_party_checks_delivered",
                },
                {
                  name: "Cheques Propios Emitidos y Entregados",
                  icon: 'mdi-clipboard-text',
                  path: "/reports/banks/own_checks_delivered",
                },
              ],
            },
            {
              name: "Calidad",
              icon: 'mdi-eye-outline',
              children: [
                {
                  name: "RG",
                  icon: 'mdi-clipboard-text',
                  children: [
                    {
                      name: "RG-010 Form. Estanqueidad",
                      icon: 'mdi-clipboard-text',
                      path: "/reports/quality/rg_010_form",
                    },
                    {
                      name: "RG-014 Form. Num. Correlativa de Radiadores",
                      icon: 'mdi-clipboard-text',
                      path: "/reports/quality/rg_014_form",
                    },
                    {
                      name: "RG-010 Reporte Estanqueidad",
                      icon: 'mdi-clipboard-text',
                      path: "/reports/quality/rg_010_report"
                    },
                  ],
                },
                { name: "Trazabilidad", icon: 'mdi-clipboard-text', path: "/reports/quality/tracing" },
              ],
            },
            {
              name: "Compras",
              icon: "mdi-shopping",
              children: [
                {
                  name: "Artículos Comprados",
                  path: "/reports/purchases/purchased_products",
                  icon: "mdi-shopping",
                },
              ],
            },
            {
              name: "Tablero de Comando",
              children: [
                {
                  name: "Resumen Actv. de la Empresa",
                  path: "/reportes/finanzas/resumenActEmpresa",
                },
              ],
            },
          ],
        },
        {
          name: "Tablero de Comando",
          icon: "mdi-chart-pie",
          path: "/tableroDeComando",
        },
      ],
    }
  },
}
</script>
  
<style>
.linkNav {
    text-decoration: none;
    padding-right: 50px;
    color: #ffffff !important;
}
</style>