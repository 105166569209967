import axios from "axios"
import {host} from "@/store/config";

export default {
  async getProvinces() {
    return await axios.get(host + '/province')
  },
  async getLocalitiesByProvince(provinceId) {
    return await axios.get(host + '/localities_by_province', {
      params: {
        provinceId
      }
    })
  },
}