export default {
  exchangeRateParameters: [],
  pricesLists: [],
  pricesListDetails: [],
  banks: [],
  bankAccounts: [],
  bankConcepts: [],
  bankMovements: [],
  bankCards: [],
  wallets: [],
}