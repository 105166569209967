import axios from "axios";
import {host} from "@/store/config";

export default {
    async getFile(data) {
        return await axios.get(host + '/attachment', {
            responseType: 'arraybuffer',
            params: {
                id: data.id,
                entity: data.entity,
            }
        })
    },
    async deleteFile(data) {
        return await axios.delete(host + '/attachment', { params: {
                id: data.id,
                entity: data.entity,
            }
        })
    },
}