import axios from 'axios';
import { host } from '@/store/config';

export default {
  async login(data) {

    return await axios.post(host + '/api/login', {username: data.user, password: data.password})
  },

  async updatePassword(data) {
    let formData = new FormData();
    formData.append('newPassword', data.newPassword)
    formData.append('confirmedPassword', data.confirmedPassword)

    return await axios.post(host + '/update_password', formData)
  },

  async getSectorsByUser(userId) {
    return await axios.get(host + '/sectors_by_user', {
      params: {
        userId: userId,
      }
    })
  }
}