import loginEndpoints from "@/store/endpoints/loginEndpoints";
import axios from 'axios';
import appEndpoints from "@/store/endpoints/appEndpoints";

export default {
  async login(context, data) {
    const response = await loginEndpoints.login(data)
    if (response && response.status === 200) {
      context.commit('loadUser', {data: data, serverData: response.data})
      context.commit('loadAuth', {data: data, serverData: response.data})
    }

    if (response.response && response.response.status === 307) {
      context.commit('loadAuth', {data: data, serverData: response.response.data})
      return response.response
    }

    return response
  },

  async updatePassword(context, data) {
    return await loginEndpoints.updatePassword(data)
  },

  logout(context) {
    context.commit('logout')
    delete axios.defaults.headers.common["Authorization"]
    delete axios.defaults.headers.common["apiKey"]
  },

  async getParameters(context) {
    const response = await appEndpoints.getParameters()
    if (response.status === 200) {
      context.commit('loadParameters', response.data)
    }
    return response.data
  },
  async saveParameters(context, data) {
    const response = await appEndpoints.saveParameters(data)
    if (response.status === 201) {
      context.commit('uploadParameters', data)
    }
  },

  async getExchangeRate() {
    const response = await appEndpoints.getExchangeRate()
    return response.data
  },

  async getSectorsByUser(context, userId) {
    const response = await loginEndpoints.getSectorsByUser(userId)
    if (response.status === 200) {
      context.commit('loadSectorsByUser', response.data)
    }
    return response
  },

  async setFromSectorIds(context, ids) {
    context.commit('setFromSectorIds', ids)
  },
  async setToSectorIds(context, ids) {
    context.commit('setToSectorIds', ids)
  },
}