import employeesEndpoints from "@/store/endpoints/employeesEndpoints";
import productionEndpoints from "@/store/endpoints/productionEndpoints";

export default {
  async getEmployees(context) {
    const response = await employeesEndpoints.getEmployees()
    if (response.status === 200) context.commit('loadEmployees', response.data)
  },
  async getSectors(context) {
    const response = await employeesEndpoints.getSectors()
    if (response.status === 200) context.commit('loadSectors', response.data)
  },

  async createOrEditEmployee(context, employee) {
    const response = await employeesEndpoints.createOrEditEmployee(employee)
    if (response.status === 201) context.commit('addEmployee', employee)
    return response
  },
  async deleteEmployee(context, index) {
    const empl = context.state.employees[index]
    const response = await employeesEndpoints.deleteEmployee(empl.id)
    if (response.status === 200) context.commit('removeEmployee', index)
    return response
  },
}