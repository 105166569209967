import axios from "axios";
import {host} from "@/store/config";

export default {
  async getFamilies(params) {
    return await axios.get(host + '/family', {
      params: params
    })
  },
  async createOrEditFamily(data) {
    return await axios.put(host + '/family', data)
  },
  async deleteFamily(uuid) {
    return await axios.delete(host + '/family', { data: {
      id: uuid,
    }})
  }
}