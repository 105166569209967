export default {
  openFile(response) {
    let blob = new Blob([response.data], {
      type: response.headers['content-type']
    })
    let blobURL = URL.createObjectURL(blob)
    window.open(blobURL)
  },

  downloadFile(response) {
    const contentHeader = response.headers['content-disposition']
    const startIndex = contentHeader.indexOf('=')
    let fileName = contentHeader.substring(startIndex + 1, contentHeader.length)
    fileName = fileName.replaceAll('"', '')
    let blob = new Blob([response.data], {
      type: response.headers['content-type']
    })

    let blobURL = URL.createObjectURL(blob)
    const link = document.createElement('a');
    link.href = blobURL;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
}
