export default {
  loadCustomerOrders(state, orders) {
    state.customerOrders = orders
  },
  loadCustomerOrderDetails(state, details) {
    state.customerOrderDetails = details
  },
  loadCustomerOrderDetailsAuthorizedForDelivery(state, details) {
    state.customerOrderDetailsAuthorizedForDelivery = details
  },

  removeCustomerOrder(state, index) {
    state.customerOrders.splice(index, 1)
  },

  uploadCustomerOrderDetail(state, data) {
    state.customerOrderDetails[data.index] = data.detail
  },
}