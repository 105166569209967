export default {
  loadEmployees(state, employees) {
    state.employees = employees
  },
  loadSectors(state, sectors) {
    state.sectors = sectors
  },

  addEmployee(state, empl) {
    state.employees.unshift(empl)
  },
  removeEmployee(state, index) {
    state.employees.splice(index, 1)
  },
}