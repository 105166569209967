export default {
  getCorrelatives(state) {
    return state.correlatives
  },
  getPressureTests(state) {
    return state.pressureTests
  },
  getCorrelativesByNumber(state) {
    return correlativeNumber => {
      return state.correlatives.filter(
        correlative => correlative.correlativeNumber.includes(correlativeNumber)
      )
    }
  },
  getPressureTestsByNumber(state) {
    return searchTest => {
      return state.pressureTests.filter(test => test.proofNumber.includes(searchTest))
    }
  },
  getReceptionControlProducts(state) {
    return state.receptionControlProducts
  },
  getReceptionControlProductsByCode(state) {
    return (searchStr, byCurrentWeek) => {
      const receptionByCode = state.receptionControlProducts.filter(
        reception => reception.product.code.toLowerCase().includes(searchStr.toLowerCase())
      )
      if (byCurrentWeek) {
        return receptionByCode.filter(reception => {
          const timeDiff = new Date().getTime() - new Date(reception.stockMovementDetail.stockMovement.generationDate).getTime()
          const totalDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
          return totalDays >= 0 && totalDays <= 7
        })
      }
      return receptionByCode
    }
  },
}