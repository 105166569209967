import purchasingEndpoints from "@/store/endpoints/purchasingEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";
import {setTablePagination} from "@/utils/paginator";

export default {
    async getPurchaseOrders(context, params) {
        const response = await purchasingEndpoints.getPurchaseOrders(params)
        if (response.status === 200) {
            context.commit('loadPurchaseOrders', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getProductPurchases(context, params) {
        const response = await purchasingEndpoints.getProductPurchases(params)
        if (response.status === 200) {
            context.commit('loadPurchaseOrderDetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getInternalRequestsDetails(context, params) {
        const response = await purchasingEndpoints.getInternalRequestsDetails(params)
        if (response.status === 200) {
            context.commit('loadInternalRequestsDetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getPurchaseOrderReport(context, id) {
        const response = await purchasingEndpoints.getPurchaseOrderReport(id)
        if (response.status === 200) reportOutputResolver.openFile(response)
    },

    async createOrEditPurchaseOrder(context, order) {
        return await purchasingEndpoints.createOrEditPurchaseOrder(order)
    },
    async deletePurchaseOrder(context, index) {
        const purchOrder = context.state.purchaseOrders[index]
        return await purchasingEndpoints.deletePurchaseOrder(purchOrder.id)
    },
    async createOrEditInternalPurchaseRequest(context, internal) {
        return await purchasingEndpoints.createOrEditInternalPurchaseRequest(internal)
    },
    async deleteInternalRequest(context, index) {
        const internalId = context.state.internalPurchaseRequests[index].id
        return await purchasingEndpoints.deleteInternalRequest(internalId)
    },
    async updateInternalPurchaseOrderDetail(context, detail) {
        await purchasingEndpoints.updateInternalPurchaseOrderDetail(detail)
    },
}