import {host} from "@/store/config";
import axios from "axios";

export default {
    async getDispatchNotes(params) {
        return await axios.get(host + '/dispatch_note', {
            params: params
        })
    },
    async getDispatchNotesByCriteria(params) {
        return await axios.get(host + '/dispatch_note_search_by', {
            params: params
        })
    },
    async getDispatchNoteDetailsByCriteria(params) {
        return await axios.get(host + '/dispatch_note_detail_search', {
            params: params
        })
    },
    async getDispatchNotesPendingOfInvoice(customerId) {
        return await axios.get(host + '/dispatch_note_pending_of_invoice', {
            params: {
                customerId: customerId,
            }
        })
    },
    async getInvoicesByNumber(params) {
        return await axios.get(host + '/invoice_by_number', {
            params: params
        })
    },
    async getDispatchNotesDeparture(params) {
        return await axios.get(host + '/dispatch_note_departure', {
            params: params
        })
    },

    async createOrEditDispatchNote(dispatchNote) {
        return await axios.post(host + '/dispatch_note', dispatchNote)
    },
    async cancelDispatchNote(id) {
        return await axios.delete(host + '/dispatch_note', {
            params: {
                id: id,
            }
        })
    },

    async validateDispatchNote(id) {
        return await axios.post(host + '/dispatch_note_validate', null, {
            params: {
                id: id,
            }
        })
    },

    async cancelDispatchNoteDetail(id) {
        return await axios.put(host + '/dispatch_note_canceller', null, {
            params: {
                id: id,
            }
        })
    },
    async revertCancelledDispatchNoteDetail(id) {
        return await axios.put(host + '/dispatch_note_detail_cancelled_revert', null, {
            params: {
                id: id,
            }
        })
    },

    async associateDispatchNoteCorrelative(assoc) {
        return await axios.post(host + '/dispatch_note_detail_correlative_relator', null, {
            params: {
                correlativeNumbers: assoc.correlativeNumbers,
                dispatchNoteNumber: assoc.dispatchNoteNumber
            }
        })
    },
    async assocDispatchInvoice(couple) {
        return await axios.post(host + '/dispatch_note_detail_invoice_relator', null, {
            params: {
                dispatchNoteDetailId: couple.dispatchNoteDetailId,
                invoiceDetailId: couple.invoiceDetailId
            }
        })
    },
    async disassociateDispatchNoteInvoice(couple) {
        return await axios.post(host + '/dispatch_note_detail_invoice_separator', null, {
            params: {
                dispatchNoteDetailId: couple.dispatchNoteDetailId,
                invoiceDetailId: couple.invoiceDetailId
            }
        })
    },

    async updateDispatchNoteDelivered(dispatchNoteData) {
        return await axios.put(host + '/dispatch_note_delivered_update', null, {
            params: {
                dispatchNoteId: dispatchNoteData.dispatchNoteId,
                deliveredDate: dispatchNoteData.deliveredDate,
            }
        })
    },
    async associateDispatchNoteWithCustomerOrder(dispatchNoteDetails) {
        let dispatchNoteDetail = dispatchNoteDetails[0]
        return await axios.post(host + '/dispatch_note_customer_order_relator', dispatchNoteDetails)
    },

    async deleteDispatchNoteCorrelativeAssociation(correlativeId) {
        return await axios.post(host + '/dispatch_note_detail_correlative_separator', null, {
            params: {
                correlativeId: correlativeId,
            }
        })
    },
}

