import productsEndpoints from "@/store/endpoints/productsEndpoints";

export default {
  async getProducts(context) {
    const response = await productsEndpoints.getProducts()
    if (response.status === 200) {
      context.commit('loadProducts', response.data);
    }
  },
  async getProductData(context, id) {
    const response = await productsEndpoints.getProductData(id)
    return response.data
  },
  async getBrands(context) {
    const response = await productsEndpoints.getBrands()
    if (response.status === 200) {
      context.commit('loadBrands', response.data)
    }
  },

  async createProduct(context, data) {
    const response = await productsEndpoints.createOrEditProduct(data)
    if (response.status === 201) {
      context.commit('addProduct', data)
    }
    return response
  },
  async editProduct(context, data) {
    const response = await productsEndpoints.createOrEditProduct(data.item)
    if (response.status === 201) {
      context.commit('updateProduct', data)
    }
    return response
  },
  async deleteProduct(context, index) {
    let item = context.state.products[index]
    const response = await productsEndpoints.deleteProduct(item.id) // Passing Product UUID
    if (response.status === 200) {
      context.commit('removeProduct', index)
    }
  },

  async createOrEditBrand(context, data) {
    const response = await productsEndpoints.createOrEditBrand(data.brand)
    if (response.status === 201) {
      if (data.index > -1) {
        context.commit('uploadBrand', data)
      } else {
        context.commit('addBrand', data.brand)
      }
    }
    return response
  },
  async deleteBrand(context, index) {
    const brand = context.state.brands[index]
    const response = await productsEndpoints.deleteBrand(brand.id)
    if (response.status === 200) context.commit('removeBrand', index)
  }
}