export default {
    loadDispatchNotes(state, dispatchNotes) {
        state.dispatchNotes = dispatchNotes
    },
    loadDispatchNoteDetails(state, dispatchNoteDetails) {
        state.dispatchNoteDetails = dispatchNoteDetails
    },
    loadInvoices(state, invoices) {
        state.invoices = invoices
    },

    addDispatchNote(state, dispatchNote) {
        state.dispatchNotes.unshift(dispatchNote)
    },
    removeDispatchNote(state, index) {
        state.dispatchNotes.splice(index, 1)
    },
    removeDispatchNoteDetail(state, index) {
        state.dispatchNoteDetails.splice(index, 1)
    }
}
