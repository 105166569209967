import axios from "axios";
import {host} from "@/store/config";
import {PDF} from "@/types/printFormats";

export default {
  async getWorkOrders(params) {
    return await axios.get(host + '/order', {
      params: params
    })
  },
  async getProductionOrder(orderData) {
    return await axios.get(host + '/order', {
      params: {
        id: orderData.id,
        number: orderData.number,
        code: orderData.code
      }
    })
  },
  async getPendingProductionOrdersByProduct(productId) {
    return await axios.get(host + '/order_pending_by_product', {
      params: {
        productId: productId
      }
    })
  },
  async getProductionOrderReport(orderId) {
    return await axios.get(host + '/order_report', {
      responseType: 'arraybuffer',
      params: {
        id: orderId,
        extension: PDF.value,
      },
    })
  },
  async getProductionOrderBatchReport(orderIds) {
    return await axios.get(host + '/order_batch_report', {
      responseType: 'arraybuffer',
      params: {
        ids: orderIds,
        extension: PDF.value,
      },
    })
  },
  async getProgrammedOrders(params) {
    return await axios.get(host + '/order_by_sectors', {
      params: params
    })
  },
  async getEmittedOrders(params) {
    return await axios.get(host + '/order_by_sectors', {
      params: params
    })
  },
  async getRelatedOrdersByNumber(number) {
    return await axios.get(host + '/related_orders', {
      params: {
        ot: number,
      }
    })
  },
  async getBarPlateCalculations() {
    return await axios.get(host + '/bar_plate_panel')
  },
  async getBarPlateCalculationByProduct(id) {
    return await axios.get(host + '/bar_plate_search_by_product', {
      params: {
        id: id,
      }
    })
  },
  async getBarPlatePanelSpreadsheet(barPlate) {
    return await axios.get(host + '/bar_plate_spreadsheet', {
      responseType: 'arraybuffer',
      params: {
        data: JSON.stringify(barPlate),
      }
    })
  },
  async getTubePanelCalculations() {
    return await axios.get(host + '/tube_panel')
  },
  async getTubePanelCalculationByProduct(id) {
    return await axios.get(host + '/tube_panel_search_by_product', {
      params: {
        productId: id,
      }
    })
  },
  async getTubePanelSpreadsheet(tubePanel) {
    return await axios.get(host + '/tube_panel_spreadsheet', {
      responseType: 'arraybuffer',
      params: {
        data: JSON.stringify(tubePanel),
      }
    })
  },
  async getIntercoolerCalculations() {
    return await axios.get(host + '/intercooler_panel')
  },
  async getIntercoolerCalculationByProduct(id) {
    return await axios.get(host + '/intercooler_panel_search_by_product', {
      params: {
        productId: id,
      }
    })
  },
  async getIntercoolerSpreadsheet(intercooler) {
    return await axios.get(host + '/intercooler_panel_spreadsheet', {
      responseType: 'arraybuffer',
      params: {
        data: JSON.stringify(intercooler),
      }
    })
  },
  async getProductionTubePanels() {
    return await axios.get(host + '/production_control_tube_panel')
  },
  async getProductionIntercoolers() {
    return await axios.get(host + '/production_control_intercooler')
  },
  async getProductionBarPlates() {
    return await axios.get(host + '/production_control_bar_plate')
  },
  async getProductionBarPlateList() {
    return await axios.get(host + '/')
  },
  async getProductionTubePanelList() {
    return await axios.get(host + '/')
  },
  async getProductionIntercoolerList() {
    return await axios.get(host + '/')
  },
  async getOperationsByCode(code) {
    return await axios.get(host + '/', {
      params: {
        code: code,
      }
    })
  },
  async getRepairs(pagination) {
    return await axios.get(host + '/repairs', {
      params: {
        limit: pagination.limit,
        page: pagination.page
      }
    })
  },
  async getRepairConcepts() {
    return await axios.get(host + '/repair_concept')
  },

  async createProductionOrderBatch(order) {
    return await axios.put(host + '/order_batch_creator', order)
  },

  async createOrEditProductionOrder(order) {
    return await axios.put(host + '/order', order)
  },
  async updateProgrammedOrder(order) {
    return await axios.post(host + '/order_from_receiving_sector_updater', order)
  },
  async updateEmittedOrder(order) {
    return await axios.post(host + '/order_update_from_issuer', order)
  },
  async deleteProductionOrder(deleteData) {
    return await axios.delete(host + '/order', {
      params: {
        id: deleteData.orderId,
        force: true,
        deleteReason: deleteData.deleteReason
      }
    })
  },

  async associateProductionCustomerOrder(assoc) {
    return await axios.post(host + '/order_and_customer_order_detail_relator', null, {
      params: {
        orderId: assoc.order.id,
        customerOrderDetailId: assoc.customerOrderDetail.id,
      }
    })
  },
  async disassociateProductionCustomerOrder(assoc) {
    return await axios.post(host + '/order_and_customer_order_detail_separator', null, {
      params: {
        orderId: assoc.order.id,
        customerOrderDetailId: assoc.customerOrderDetail.id,
      }
    })
  },
  async associateProductionOrders(assoc) {
    return await axios.post(host + '/parent_and_child_order_relator', null, {
      params: {
        parentOrderId: assoc.parentOrder.id,
        childOrderId: assoc.childOrder.id
      }
    })
  },
  async disassociateProductionOrders(assoc) {
    return await axios.post(host + '/parent_and_child_order_separator', null, {
      params: {
        parentOrderId: assoc.parentOrder.id,
        childOrderId: assoc.childOrder.id
      }
    })
  },

  async createOrEditBarPlate(barPlate) {
    return await axios.put(host + '/bar_plate_panel', barPlate)
  },
  async createOrEditTubePanel(tubePanel) {
    return await axios.put(host + '/tube_panel', tubePanel)
  },
  async createOrEditIntercooler(intercooler) {
    return await axios.put(host + '/intercooler_panel', intercooler)
  },

  async updateProductionControlBarPlate(bar) {
    return await axios.post(host + '/production_control_bar_plate_update', bar)
  },
  async updateProductionControlTubePanel(tube) {
    return await axios.post(host + '/production_control_tube_panel_update', tube)
  },
  async updateProductionControlIntercooler(intercooler) {
    return await axios.post(host + '/production_control_intercooler_update', intercooler)
  },
  async updateOperation(operation) {
    return await axios.put(host + '/', operation)
  },

  async createOrEditRepairConcept(concept) {
    return await axios.put(host + '/repair_concept', concept)
  },
  async deleteRepairConcept(id) {
    return await axios.delete(host + '/repair_concept', {
      params: {
        id
      }
    })
  },
  async updateRepairItemStatus(repair) {
    const queryParam = repair.id
    return await axios.post(host + '/repair_item_status_update?repairId=' + queryParam, [...repair.items])
  },
}