import familiesEndpoints from "@/store/endpoints/familiesEndpoints";
import {setTablePagination} from "@/utils/paginator";

export default {
  async getFamilies(context, params) {
    const response = await familiesEndpoints.getFamilies(params)
    if (response.status === 200) {
      context.commit('loadFamilies', response.data.data)
      setTablePagination(context, response.data)
    }
  },
  async createFamily(context, data) {
    return await familiesEndpoints.createOrEditFamily(data)
  },
  async editFamily(context, data) {
    const response = await familiesEndpoints.createOrEditFamily(data.item)
    if (response.status === 201) {
      context.commit('updateFamily', data)
    }
    return response
  },
  async deleteFamily(context, index) {
    let item = context.state.families[index]
    return await familiesEndpoints.deleteFamily(item.id)
  }
}