export default {
    dispatchNotes: [],
    dispatchNote: {},
    dispatchNoteDetails: [],
    dispatchNotesPendingOfInvoice: [],
    detailsByDispatchNote: [],
    invoiceNumberDetails: [],
    dispatchNotesDeparture: [],
    dispatchPendingOfDeparture: [],
    invoices: [],
}