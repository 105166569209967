import axios from "axios";
import {host} from "@/store/config";

export default {
  async getEmployees() {
    return await axios.get(host + '/employees')
  },
  async getSectors() {
    return await axios.get(host + '/sectors')
  },

  async createOrEditEmployee(employee) {
    return await axios.put(host + '/employee', employee)
  },
  async deleteEmployee(id) {
    return await axios.delete(host + '/employee', {
      params: {
        id: id,
      }
    })
  },
}