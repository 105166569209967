import dispatchNotesEndpoints from "@/store/endpoints/dispatchNotesEndpoints";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";
import {setTablePagination} from "@/utils/paginator";

export default {
    async getDispatchNotes(context, params) {
        const response = await dispatchNotesEndpoints.getDispatchNotes(params)
        if (response.status === 200) {
            context.commit('loadDispatchNotes', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getDispatchNotesByCriteria(context, params) {
        const response = await dispatchNotesEndpoints.getDispatchNotesByCriteria(params)
        if (response.status === 200) {
            context.commit('loadDispatchNotes', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getDispatchNoteDetailsByCriteria(context, params) {
        const response = await dispatchNotesEndpoints.getDispatchNoteDetailsByCriteria(params)
        if (response.status === 200) {
            context.commit('loadDispatchNoteDetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getDispatchNoteReport(context, printData) {
        const data = {
            params: {
                id: printData.id,
            },
            endpoint: '/dispatch_note_report',
        }
        const response = await reportsEndpoints.getReport(data)
        if (response.status === 200) {
            if (printData.isDownload)
                reportOutputResolver.downloadFile(response)
            else
                reportOutputResolver.openFile(response)
        }
    },
    async getDispatchNotesPendingOfInvoice(context, customerId) {
        const response = await dispatchNotesEndpoints.getDispatchNotesPendingOfInvoice(customerId)
        if (response.status === 200) context.commit('loadDispatchNoteDetails', response.data)
    },
    async getInvoicesByNumber(context, params) {
        const response = await dispatchNotesEndpoints.getInvoicesByNumber(params)
        if (response.status === 200) {
            context.commit('loadInvoices', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getDispatchNotesDeparture(context, params) {
        const response = await dispatchNotesEndpoints.getDispatchNotesDeparture(params)
        if (response.status === 200) {
            context.commit('loadDispatchNotes', response.data.data)
            setTablePagination(context, response.data)
        }
    },

    async createOrEditDispatchNote(context, dispatchNote) {
        const response = await dispatchNotesEndpoints.createOrEditDispatchNote(dispatchNote)
        if (response.status === 201) context.commit('addDispatchNote', dispatchNote)
        return response
    },
    async cancelDispatchNote(context, data) {
        const dispatchNote = data.isFromDetail
            ? context.state.dispatchNoteDetails[data.index].dispatchNote
            : context.state.dispatchNotes[data.index]

        const response = await dispatchNotesEndpoints.cancelDispatchNote(dispatchNote.id)
        if (response.status === 200) {
            dispatchNote.cancelled = true
        }
        return response
    },
    async validateDispatchNote(context, data) {
        const dispatchNote = data.isFromDetail
            ? context.state.dispatchNoteDetails[data.index].dispatchNote
            : context.state.dispatchNotes[data.index]

        const response = await dispatchNotesEndpoints.validateDispatchNote(dispatchNote.id)
        if (response.status === 200) dispatchNote.cancelled = false
        return response
    },
    async cancelDispatchNoteDetail(context, index) {
        const dispatchNoteDetail = context.state.dispatchNoteDetails[index]
        const response = await dispatchNotesEndpoints.cancelDispatchNoteDetail(dispatchNoteDetail.id)
        if (response.status === 200) context.commit('removeDispatchNoteDetail', index)
    },
    async revertCancelledDispatchNoteDetail(context, id) {
        return await dispatchNotesEndpoints.revertCancelledDispatchNoteDetail(id)
    },
    async associateDispatchNoteCorrelative(context, assoc) {
        return await dispatchNotesEndpoints.associateDispatchNoteCorrelative(assoc)
    },
    async assocDispatchInvoice(context, couple) {
        const response = await dispatchNotesEndpoints.assocDispatchInvoice(couple)
    },
    async disassociateDispatchNoteInvoice(context, couple) {
        return await dispatchNotesEndpoints.disassociateDispatchNoteInvoice(couple)
    },
    async updateDispatchNoteDelivered(context, dispatchNoteData) {
        return await dispatchNotesEndpoints.updateDispatchNoteDelivered(dispatchNoteData)
    },
    async associateDispatchNoteWithCustomerOrder(context, dispatchNoteDetails) {
        return await dispatchNotesEndpoints.associateDispatchNoteWithCustomerOrder(dispatchNoteDetails)
    },
    async deleteDispatchNoteCorrelativeAssociation(context, correlativeId) {
        return await dispatchNotesEndpoints.deleteDispatchNoteCorrelativeAssociation(correlativeId);
    },
}