import {PurchaseOrders, Suppliers} from "@/types/entities";

export default {
  getPurchaseOrders(state) {
    return state.purchaseOrders
  },
  getPurchaseOrderDetails(state) {
    return state.purchaseOrderDetails
  },
  getInternalPurchaseRequests(state) {
    return state.internalPurchaseRequests
  },
}