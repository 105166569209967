export default {
  loadPurchaseOrders(state, orders) {
    state.purchaseOrders = orders
  },
  loadPurchaseOrderDetails(state, details) {
    state.purchaseOrderDetails = details
  },
  loadInternalRequestsDetails(state, details) {
    state.internalPurchaseRequests = details
  },
}