import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/App/Login.vue')
  },
  {
    path: '/update_password',
    name: 'updatePassword',
    component: () => import('../views/App/PasswordUpdate.vue')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products/Products.vue')
  },
  {
    path: '/families',
    name: 'families',
    component: () => import('../views/Products/Families.vue')
  },
  {
    path: '/subfamilies',
    name: 'subfamilies',
    component: () => import('../views/Products/Subfamilies.vue')
  },
  {
    path: '/brands',
    name: 'brands',
    component: () => import('../views/Products/Brands.vue')
  },

  {
    path: '/customers/list',
    name: 'customers',
    component: () => import('../views/Customers/Customers.vue')
  },
  {
    path: '/customers/customersCA',
    name: 'customersCA',
    component: () => import('../views/Customers/CustomersCA.vue'),
    props: true
  },
  {
    path: '/customers/customer_proofs_imputations',
    name: 'customerProofsImputations',
    component: () => import('../views/Customers/CustomerProofsImputations.vue'),
    props: true,
  },
  {
    path: '/customers/prices_lists/new_list',
    name: 'new_list',
    component: () => import('../views/Customers/PricesLists/NewPriceList.vue')
  },
  {
    path: '/customers/prices_lists/add_products',
    name: 'add_products',
    component: () => import('../views/Customers/PricesLists/AddProductsToList.vue')
  },
  {
    path: '/customers/prices_lists/see_prices',
    name: 'see_prices',
    component: () => import('../views/Customers/PricesLists/SeeProductPrices.vue')
  },
  {
    path: '/customers/new_quotation',
    name: 'newQuotation',
    component: () => import('../views/Customers/NewQuotation.vue')
  },
  {
    path: '/customers/quotation_list',
    name: 'quotationsList',
    component: () => import('../views/Customers/QuotationsList.vue')
  },
  {
    path: '/customers/transports',
    name: 'transports',
    component: () => import('../views/Customers/Transports.vue')
  },
  {
    path: '/customers/sellers',
    name: 'sellers',
    component: () => import('../views/Customers/Sellers.vue')
  },

  {
    path: '/suppliers/list',
    name: 'suppliers',
    component: () => import('../views/Suppliers/Suppliers.vue')
  },
  {
    path: '/suppliers/suppliersCA',
    name: 'suppliersCA',
    component: () => import('../views/Suppliers/SuppliersCA.vue'),
    props: true,
  },
  {
    path: '/suppliers/supplier_proofs_imputations',
    name: 'supplierProofsImputations',
    component: () => import('../views/Suppliers/SupplierProofsImputations.vue'),
    props: true,
  },
  {
    path: '/suppliers/imputations_expenses',
    name: 'imputations_expenses',
    component: () => import('../views/Suppliers/ImputationsExpenses.vue')
  },
  {
    path: '/suppliers/payments_schedule',
    name: 'paymentsSchedule',
    component: () => import('../views/Suppliers/PaymentsSchedule.vue')
  },

  {
    path: '/parameters/general',
    name: 'generalParameters',
    component: () => import('../views/App/Parameters.vue')
  },
  {
    path: '/parameters/accounting',
    name: 'accountingParameters',
    component: () => import('../views/Accounting/AccountingParameters.vue')
  },

  {
    path: '/engineering/revisions_history',
    name: 'revisionsHistory',
    component: () => import('../views/Engineering/RevisionsHistory.vue')
  },
  {
    path: '/engineering/formulas/simple',
    name: 'formulas',
    component: () => import('../views/Engineering/Formulas.vue')
  },
  {
    path: '/engineering/formulas/batch',
    name: 'formulaBatch',
    component: () => import('../views/Engineering/FormulaBatch.vue')
  },

  {
    path: '/quality/correlative_numbering',
    name: 'correlativeNumbering',
    component: () => import('../views/Quality/CorrelativeNumbering.vue')
  },
  {
    path: '/quality/tightness_control',
    name: 'tightnessControl',
    component: () => import('../views/Quality/TightnessControl.vue')
  },
  {
    path: '/quality/reception_control',
    name: 'receptionControl',
    component: () => import('../views/Quality/ReceptionControl.vue')
  },

  {
    path: '/production/programming_board',
    name: 'programmingBoard',
    component: () => import('../views/Production/WorkOrders/ProgrammingBoard.vue')
  },
  {
    path: '/production/new_work_order',
    name: 'newWorkOrder',
    component: () => import('../views/Production/WorkOrders/NewWorkOrder.vue'),
    props: true,
  },
  {
    path: '/production/new_batch_order',
    name: 'newBatchOrder',
    component: () => import('../views/Production/WorkOrders/NewProductionOrderBatch.vue'),
  },
  {
    path: '/production/order_tracing',
    name: 'productionOrderTracing',
    component: () => import('../views/Production/WorkOrders/ProductionOrderTracing.vue'),
  },
  {
    path: '/production/work_orders_list',
    name: 'workOrdersList',
    component: () => import('../views/Production/WorkOrders/WorkOrdersList.vue')
  },
  {
    path: '/production/associate_wo_customer_order',
    name: 'associateWOCustomerOrder',
    component: () => import('../views/Production/WorkOrders/AssociateWOCustomerOrder.vue')
  },
  {
    path: '/production/associate_wo_wo',
    name: 'associateWOWO',
    component: () => import('../views/Production/WorkOrders/AssociateWOs.vue')
  },
  {
    path: '/production/priority_board',
    name: 'priorityBoard',
    component: () => import('../views/Production/PriorityBoard.vue')
  },
  {
    path: '/production/new_order',
    name: 'newOrder',
    component: () => import('../views/Production/CustomerOrders/NewCustomerOrder.vue'),
    props: true
  },
  {
    path: '/production/pending_customer_orders',
    name: 'pendingCustomerOrders',
    component: () => import('../views/Production/CustomerOrders/CustomerOrdersList.vue'),
  },
  {
    path: '/production/delivered_customer_orders',
    name: 'deliveredCustomerOrders',
    component: () => import('../views/Production/CustomerOrders/CustomerOrdersList.vue'),
  },
  {
    path: '/production/authorize_deliveries',
    name: 'authorizeDeliveries',
    component: () => import('../views/Production/CustomerOrders/AuthorizeDeliveries.vue')
  },
  {
    path: '/production/authorized_deliveries',
    name: 'authorizedDeliveries',
    component: () => import('../views/Production/CustomerOrders/AuthorizedDeliveries.vue')
  },

  {
    path: '/production/panel_calculations/new_bar_plate',
    name: 'newBarPlateCalculation',
    component: () => import('../views/Production/Calculation/NewBarPlate.vue')
  },
  {
    path: '/production/panel_calculations/print_bar_plate',
    name: 'PrintBarPlate',
    component: () => import('../views/Production/Calculation/PrintBarPlate.vue')
  },
  {
    path: '/production/panel_calculations/simple_double_tube',
    name: 'SimpleDoubleTube',
    component: () => import('../views/Production/Calculation/NewSimpleDoubleTube.vue')
  },
  {
    path: '/production/panel_calculations/intercooler',
    name: 'newIntercooler',
    component: () => import('../views/Production/Calculation/NewIntercooler.vue')
  },
  {
    path: '/production/panel_calculations/print_simple_double_tube',
    name: 'PrintSimpleDoubleTube',
    component: () => import('../views/Production/Calculation/PrintSimpleDoubleTube.vue')
  },
  {
    path: '/production/panel_calculations/print_intercooler',
    name: 'PrintIntercooler',
    component: () => import('../views/Production/Calculation/PrintIntercooler.vue')
  },
  {
    path: '/production/control/panel_process_tracing',
    name: 'PanelProcessTracing',
    component: () => import('../views/Production/PanelProcessTracing.vue')
  },
  {
    path: '/production/control/process_closure',
    name: 'processClosure',
    component: () => import('../views/Production/ProcessClosure.vue')
  },
  {
    path: '/production/repairs/list',
    name: 'repairsList',
    component: () => import('../views/Production/Repair/RepairsList.vue')
  },
  {
    path: '/production/repairs/concepts',
    name: 'repairConcepts',
    component: () => import('../views/Production/Repair/RepairConcepts.vue')
  },

  {
    path: '/employees/list',
    name: 'employeesList',
    component: () => import('../views/HumanResources/Employees.vue')
  },

  {
    path: '/banks/list',
    name: 'banksList',
    component: () => import('../views/Banks/BanksList.vue')
  },
  {
    path: '/banks/accounts',
    name: 'banksAccounts',
    component: () => import('../views/Banks/BankAccounts.vue')
  },
  {
    path: '/banks/concepts',
    name: 'bankConcepts',
    component: () => import('../views/Banks/BankConcepts.vue')
  },
  {
    path: '/banks/new_movement',
    name: 'newBankMovement',
    component: () => import('../views/Banks/NewBankMovement.vue'),
    props: true,
  },
  {
    path: '/banks/movements',
    name: 'bankMovements',
    component: () => import('../views/Banks/BankMovementList.vue')
  },
  {
    path: '/banks/cards',
    name: 'bankCards',
    component: () => import('../views/Banks/BankCards.vue')
  },
  {
    path: '/banks/wallets',
    name: 'virtualWallets',
    component: () => import('../views/Banks/Wallets.vue')
  },

  {
    path: '/accounting/account_plannings',
    name: 'accountPlannings',
    component: () => import('../views/Accounting/AccountPlannings.vue')
  },
  {
    path: '/accounting/common_accounting_entries',
    name: 'commonAccountingEntries',
    component: () => import('../views/Accounting/AccountingEntries.vue')
  },

  {
    path: '/purchase_orders/new',
    name: 'newPurchaseOrder',
    component: () => import('../views/PurchaseOrders/NewPurchaseOrder.vue')
  },
  {
    path: '/purchase_orders/list',
    name: 'purchaseOrdersList',
    component: () => import('../views/PurchaseOrders/PurchaseOrdersList.vue')
  },
  {
    path: '/purchase_orders/modify',
    name: 'modifyPurchaseOrders',
    component: () => import('../views/PurchaseOrders/ModifyPurchaseOrder.vue')
  },
  {
    path: '/purchase_orders/new_internal_order',
    name: 'newInternalOrder',
    component: () => import('../views/PurchaseOrders/NewInternalPurchaseRequest.vue')
  },
  {
    path: '/purchase_orders/internal_orders_list',
    name: 'internalOrdersList',
    component: () => import('../views/PurchaseOrders/InternalPurchaseRequestsList.vue')
  },

  {
    path: '/stock/entry_departure',
    name: 'entryDeparture',
    component: () => import('../views/Stock/NewStockMovement.vue')
  },
  {
    path: '/stock/entries_list',
    name: 'entriesList',
    component: () => import('../views/Stock/StockMovementsList.vue')
  },
  {
    path: '/stock/stock_movement_concepts',
    name: 'stockMovementConcepts',
    component: () => import('../views/Stock/StockMovementConcepts.vue')
  },
  {
    path: '/stock/new_dispatch_note_departure',
    name: 'newDispatchNoteDeparture',
    component: () => import('../views/Stock/NewDispatchNoteDeparture.vue')
  },
  {
    path: '/stock/departures_list',
    name: 'departuresList',
    component: () => import('../views/Stock/DispatchNotesDepartureList.vue')
  },

  {
    path: '/dispatch_notes/new_dispatch_note',
    name: 'newDispatchNote',
    component: () => import('../views/DispatchNotes/NewDispatchNote.vue')
  },
  {
    path: '/dispatch_notes/show_dispatch_notes',
    name: 'showDispatchNotes',
    component: () => import('../views/DispatchNotes/ShowDispatchNotes.vue')
  },
  {
    path: '/dispatch_notes/cancel_dispatch_notes',
    name: 'cancelDispatchNotes',
    component: () => import('../views/DispatchNotes/CancelPendingDispatchNotes.vue')
  },
  {
    path: '/dispatch_notes/associate_dispatch_note_correlative',
    name: 'associateDispatchNoteCorrelative',
    component: () => import('../views/DispatchNotes/AssociateDispatchNoteCorrelative.vue')
  },
  {
    path: '/dispatch_notes/assoc_dispatch_invoice',
    name: 'associateDispatchInvoice',
    component: () => import('../views/DispatchNotes/AssociateDispatchInvoice.vue')
  },
  {
    path: '/dispatch_notes/assoc_dispatch_customer_order',
    name: 'associateDispatchCustomerOrder',
    component: () => import('../views/DispatchNotes/AssociateDispatchCustomerOrder.vue')
  },

  {
    path: '/afip/proofs_manual_load',
    name: 'proofsManualLoad',
    component: () => import('../views/Afip/ProofsManualLoad.vue')
  },
  {
    path: '/afip/verify_received_proofs',
    name: 'verifyReceivedProofs',
    component: () => import('../views/Afip/VerifyAfipReceivedProofs.vue')
  },
  {
    path: '/afip/perception_txt',
    name: 'perceptionTxt',
    component: () => import('../views/Afip/PerceptionTxt.vue')
  },

  {
    path: '/reports/production/work_orders_by_sector',
    name: 'workOrdersBySector',
    component: () => import('../views/Reports/Production/WorkOrdersBySector.vue')
  },
  {
    path: '/reports/production/work_orders_by_customer',
    name: 'workOrdersByCustomer',
    component: () => import('../views/Reports/Production/WorkOrdersByCustomer.vue')
  },
  {
    path: '/reports/production/WO_history_by_product',
    name: 'woHistoryByProduct',
    component: () => import('../views/Reports/Production/WOHistoryByProduct.vue')
  },

  {
    path: '/reports/sells/sells_iva',
    name: 'sellsIva',
    component: () => import('@/views/Reports/Sales/SalesIVABook.vue')
  },
  {
    path: '/reports/sells/sells_by_province',
    name: 'sellsByProvince',
    component: () => import('@/views/Reports/Sales/SalesByProvince.vue')
  },
  {
    path: '/reports/sells/sells_ranking',
    name: 'sellsRanking',
    component: () => import('@/views/Reports/Sales/SalesRanking.vue')
  },
  {
    path: '/reports/sells/sells_by_customer_and_period',
    name: 'sellsByCustomerAndPeriod',
    component: () => import('@/views/Reports/Sales/SalesByCustomerAndPeriod.vue')
  },
  {
    path: '/reports/sells/sells_by_family_and_subfamily',
    name: 'sellsByFamilyAndSubfamily',
    component: () => import('@/views/Reports/Sales/SalesByFamilyAndSubfamily.vue')
  },
  {
    path: '/reports/sells/interests_for_out_term_payments',
    name: 'interestsForOutTermPayments',
    component: () => import('@/views/Reports/Sales/OutOfTermInterests.vue')
  },
  {
    path: '/reports/sells/expired_balances_summary',
    name: 'expiredBalancesSummary',
    component: () => import('@/views/Reports/Sales/ExpiredDebtorBalances.vue'),
    props: {summaryType: 'expiredBalances'}
  },
  {
    path: '/reports/sells/debtor_balance_summary',
    name: 'debtorBalanceSummary',
    component: () => import('@/views/Reports/Sales/ExpiredDebtorBalances.vue'),
    props: {summaryType: 'debtorBalance'}
  },
  {
    path: '/reports/sells/customer_ca_summary',
    name: 'customerCaSummary',
    component: () => import('@/views/Reports/Sales/CustomerCASummary.vue'),
  },
  {
    path: '/reports/sells/sells_commissions',
    name: 'sellsCommissions',
    component: () => import('@/views/Reports/Sales/SellersCommissions.vue'),
  },
  {
    path: '/reports/sells/collections_in_a_period',
    name: 'collectionsInAPeriod',
    component: () => import('@/views/Reports/Sales/CollectionsInPeriod.vue'),
  },
  {
    path: '/reports/sells/prices_list',
    name: 'pricesList',
    component: () => import('@/views/Reports/Sales/PricesListReport.vue'),
  },
  {
    path: '/reports/sells/prices_lists_by_customer',
    name: 'pricesListByCustomer',
    component: () => import('@/views/Reports/Sales/PricesListsByCustomer.vue'),
  },

  {
    path: '/reports/suppliers/supplier_ca_summary',
    name: 'supplierCASummary',
    component: () => import('../views/Reports/Suppliers/SupplierCASummary.vue'),
  },
  {
    path: '/reports/suppliers/purchases_book',
    name: 'purchasesBook',
    component: () => import('../views/Reports/Suppliers/PurchasesIVABook.vue'),
  },
  {
    path: '/reports/suppliers/creditor_balance',
    name: 'creditorBalance',
    component: () => import('../views/Reports/Suppliers/CreditorBalance.vue'),
  },
  {
    path: '/reports/suppliers/payment_orders',
    name: 'paymentOrders',
    component: () => import('../views/Reports/Suppliers/PaymentOrdersReport.vue'),
  },
  {
    path: '/reports/suppliers/accumulated_by_imputations',
    name: 'accumulatedByImputations',
    component: () => import('../views/Reports/Suppliers/AccumulatedByImputations.vue'),
  },
  {
    path: '/reports/suppliers/imputation_details',
    name: 'imputationDetails',
    component: () => import('../views/Reports/Suppliers/ImputationDetailsReport.vue'),
  },

  {
    path: '/reports/customer_orders/orders_by_customer_period',
    name: 'ordersByCustomerPeriod',
    component: () => import('../views/Reports/CustomerOrders/OrdersByCustomerAndPeriod.vue'),
  },

  {
    path: '/reports/stock/stock_movement_history',
    name: 'stockMovementHistory',
    component: () => import('../views/Reports/Stock/StockMovementHistory.vue'),
  },

  {
    path: '/reports/banks/bank_movement_history',
    name: 'bankMovementHistory',
    component: () => import('../views/Reports/Banks/BankMovementHistory.vue'),
  },
  {
    path: '/reports/banks/third_party_checks_inventory',
    name: 'thirdPartyChecksInventory',
    component: () => import('../views/Reports/Banks/ThirdPartyChecksInventory.vue'),
  },
  {
    path: '/reports/banks/third_party_checks_delivered',
    name: 'thirdPartyChecksDelivered',
    component: () => import('../views/Reports/Banks/ThirdPartyChecksDelivered.vue'),
  },
  {
    path: '/reports/banks/own_checks_delivered',
    name: 'ownChecksDelivered',
    component: () => import('../views/Reports/Banks/OwnChecksDelivered.vue'),
  },

  {
    path: '/reports/quality/rg_010_form',
    name: 'rg010Form',
    component: () => import('../views/Reports/Quality/RG010TightnessForm.vue'),
  },
  {
    path: '/reports/quality/rg_010_report',
    name: 'rg010Report',
    component: () => import('../views/Reports/Quality/RG010TightnessReport.vue'),
  },
  {
    path: '/reports/quality/rg_014_form',
    name: 'rg014Form',
    component: () => import('../views/Reports/Quality/RG014CorrelativeForm.vue'),
  },
  {
    path: '/reports/quality/tracing',
    name: 'productTracing',
    component: () => import('../views/Reports/Quality/ProductTracing.vue'),
  },

  {
    path: '/reports/purchases/purchased_products',
    name: 'purchasedProducts',
    component: () => import('../views/Reports/Purchases/PurchasesBySupplierAndPeriod.vue'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login') {
    localStorage.setItem('LS_ROUTE_KEY', to.name)
  }

  const isAuthenticated = store.getters["app/getIfAuthenticated"]
  const shouldLogin = Boolean((to.name !== 'login') && !isAuthenticated)
  const shouldNotLogin = Boolean((to.name === 'login') && isAuthenticated)

  if (shouldLogin) {
    next({ name: 'login' })
    return
  }
  if (shouldNotLogin) {
    next({ name: 'home' })
    return
  }
  next()
})

export default router
