export default {
  loadSuppliers(state, suppliers) {
    state.suppliers = suppliers
  },
  loadCostCenterImputations(state, imputations) {
    state.costCenterImputations = imputations
  },
  loadThirdPartyChecks(state, checks) {
    state.thirdPartyChecks = checks
  },
  loadSupplierCurrentAccountDetails(state, details) {
    state.supplierCurrentAccountDetails = details
  },
  loadSupplierProofsForImputation(state, proofs) {
    proofs.forEach(proof => {
      proof.apply = 0
      proof.check = false
    })
    state.supplierProofsForImputation = proofs
  },
  loadSupplierProofsForCompensation(state, proofs) {
    proofs.forEach(proof => {
      proof.apply = 0
    })
    state.supplierProofsForCompensation = proofs
  },
  loadPaymentsAndCreditNotes(state, proofs) {
    proofs.forEach(proof => proof.apply = 0)
    state.paymentsAndCreditNotes = proofs
  },
  loadImputations(state, imputations) {
    state.imputations = imputations
  },
  loadPaymentsSchedule(state, payments) {
    state.paymentsSchedule = payments
  },
  loadOwnChecksSchedule(state, checks) {
    state.ownChecksSchedule = checks
  },

  addSupplier(state, supplier) {
    state.suppliers.unshift(supplier)
  },
  updateSupplier(state, data) {
    state.suppliers[data.index] = data.item
  },
  removeSupplier(state, index) {
    state.suppliers.splice(index, 1)
  },

  addCostCenterImputation(state, imputation) {
    state.costCenterImputations.unshift(imputation)
  },
  removeCostCenterImputation(state, index) {
    state.costCenterImputations.splice(index, 1)
  },

  removeSupplierImputation(state, index) {
    state.imputations.splice(index, 1)
  }
}