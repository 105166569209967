export default {
    getDispatchNotes(state) {
        return state.dispatchNotes
    },
    getDispatchNoteDetails(state) {
        return state.dispatchNoteDetails
    },
    getDispatchNoteDetailsFiltered: (state) => (searchType, searchStr) => {
        if (searchType === 'customer') {
            return state.dispatchNoteDetails.filter(
                detail => detail.dispatchNote.customer?.socialReason.toLowerCase().includes(searchStr.toLowerCase())
            )
        }
        if (searchType === 'supplier') {
            return state.dispatchNoteDetails.filter(
                detail => detail.dispatchNote.supplier?.socialReason.toLowerCase().includes(searchStr.toLowerCase())
            )
        }
        if (searchType === 'code') {
            return state.dispatchNoteDetails.filter(
                detail => detail.product.code.toLowerCase().includes(searchStr.toLowerCase())
            )
        }
    },
    getInvoices(state) {
        return state.invoices
    }
}