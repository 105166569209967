import axios from "axios";
import {host} from "@/store/config";

export default {
    async getSuppliers() {
        return await axios.get(host + '/suppliers')
    },
    async getSupplierData(id) {
        return await axios.get(host + '/suppliers', {
            params: {
                id: id,
            }
        })
    },
    async getCostCenterImputations() {
        return await axios.get(host + '/cost_center_imputations')
    },
    async getSupplierCurrentAccountDetails(params) {
        return await axios.get(host + '/supplier_current_account_details', {
            params: params
        })
    },
    async getSupplierProofsForImputation(currentAccountId) {
        return await axios.get(host + '/proofs_pending_for_imputation', {
            params: {
                currentAccountId: currentAccountId,
            }
        })
    },
    async getPaymentsAndCreditNotes(currentAccountId) {
        return await axios.get(host + '/proofs_to_apply_on_imputations', {
            params: {
                currentAccountId: currentAccountId,
            }
        })
    },
    async getImputations(params) {
        return await axios.get(host + '/payment_order_imputation', {
            params: params
        })
    },
    async getSupplierProofsForCompensationPaged(params) {
        return await axios.get(host + '/supplier_proofs_for_compensation', {
            params: params
        })
    },
    async getCommercialProofDetailSubtotal(detail) {
        return await axios.post(host + '/commercial_proof_detail_sub_total', detail)
    },

    async deleteContact(id) {
        return await axios.delete(host + '/supplier_contact', {
            params: {
                id: id,
            }
        })
    },
    async deleteCurrentAccount(account) {
        return await axios.delete(host + '/current_account', {
            params: {
                id: account.id,
                type: account.type
            }
        })
    },
    async deleteBankAccount(id) {
        return await axios.delete(host + '/supplier_bank_account', {
            params: {
                id: id,
            }
        })
    },
    async createOrEditSupplier(data) {
        let formData = new FormData()
        data.attachment.forEach(function (file) {
            formData.append('files[]', file, file.fileName)
        })
        formData.append('data', JSON.stringify(data.item))
        return await axios.post(host + '/supplier', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    async deleteSupplier(id) {
        return await axios.delete(host + '/suppliers', {
            params: {
                id: id
            }
        })
    },

    async createOrEditCostCenterImputation(costCenterImp) {
        return await axios.put(host + '/cost_center_imputation', costCenterImp)
    },
    async deleteCostCenterImputation(id) {
        return await axios.delete(host + '/cost_center_imputation', {
            params: {
                id: id,
            }
        })
    },

    async createOrEditCommercialSupplierProof(data) {
        let formData = new FormData()
        data.attachment.forEach(function (file) {
            formData.append('files[]', file, file.fileName)
        })
        formData.append('data', JSON.stringify(data.supplierProof))

        return await axios.post(host + '/commercial_supplier_proof', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    async deleteCommercialSupplierProofDetail(id) {
        return await axios.delete(host + '/commercial_supplier_proof_detail', {
            params: {
                id,
            }
        })
    },
    async verifyReceivedProofs(data) {
        let formData = new FormData()
        data.attachment.forEach(function (file) {
            formData.append('files[]', file, file.fileName)
        })
        return await axios.post(host + '/verify_received_proofs', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {
                month: data.month,
                year: data.year,
            },
            responseType: 'arraybuffer',
        })
    },
    async createPaymentOrder(payment) {
        return await axios.put(host + '/supplier_payment_order', payment)
    },
    async createCurrentAccountBalance(balance) {
        return await axios.put(host + '/supplier_current_account_movement_balance', balance)
    },
    async saveCurrentAccountNotes(supplierCurrentAccount) {
        return await axios.post(host + '/supplier_current_account_observations', supplierCurrentAccount)
    },
    async deleteSupplierCurrentAccountDetail(id) {
        return await axios.delete(host + '/supplier_current_account_detail', {
                params: {
                    id: id,
                }
            }
        )
    },

    async createSupplierImputations(imputations) {
        return await axios.put(host + '/payment_order_imputation', imputations)
    },
    async deleteSupplierImputation(id) {
        return await axios.delete(host + '/payment_order_imputation', {
            params: {
                id: id,
            }
        })
    }
}