export default {
  suppliers: [],
  thirdPartyChecks: [],
  costCenterImputations: [],
  supplierCurrentAccountDetails: [],
  supplierProofsForImputation: [],
  supplierProofsForCompensation: [],
  paymentsAndCreditNotes: [],
  imputations: [],
  paymentsSchedule: [],
  ownChecksSchedule: []
}