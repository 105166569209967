<template>
<v-container>
  <p class="mt-4 text-lg-h4 text-md-center font-weight-bold blue--text">Bienvenido/a, {{ getUser }}</p>
</v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      getUser: 'app/getUser'
    })
  }
}
</script>
