export default {
    loadProducts(state, products) {
        state.products = products
    },
    loadBrands(state, brands) {
        state.brands = brands
    },

    addProduct(state, product) {
        state.products.unshift(product)
    },
    updateProduct(state, data) {
        Object.assign(state.products[data.index], data.item)
    },
    removeProduct(state, index) {
        state.products.splice(index, 1)
    },

    addBrand(state, brand) {
        state.brands.unshift(brand)
    },
    uploadBrand(state, data) {
        Object.assign(state.brands[data.index], data.brand)
    },
}